/* General Reset and Body Style */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
    overflow-x: hidden; /* Prevent horizontal overflow */
}

body {
    height: 100%;
    font-family: 'Poppins', sans-serif;
    background-color: #1c1c1c;
    color: #fff;
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    background-image: url('./images/background.jpg'); /* Add background image */
    background-size: cover; /* Ensure the image covers the entire viewport */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    flex-direction: column; /* Stack the content vertically */
    overflow-x: hidden; /* Prevent horizontal overflow */
}


/* Header and footer styling */
.App-header, footer {
    width: 100%;
    padding: 20px;
    text-align: center;
    color: #ff4500;
}

footer {
    margin-top: auto;
    color: #aaa;
    font-size: 0.9rem;
}

/* Main container with expanded width */
main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    width: 100%; /* Occupy full width */
    max-width: 1200px; /* Change this value to your desired max width */
    margin: 20px auto; /* Auto margins keep the element centered */
}


footer {
    margin-top: auto;
    color: #aaa;
    font-size: 0.9rem;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #ffa500;
}

ul {
    list-style-type: none;
    padding: 0;
}

ul li {
    margin: 5px 0;
}

.icon {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
}

.ourlinks a {
    text-decoration: none;
    color: white;
}

/* Title and logo */
.davinci-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;
}

.davinci-logo {
    width: 100px;
    height: auto;
    margin-bottom: 10px;
}

.davinci-title {
    font-size: 3rem;
    font-weight: bold;
    color: #ffa500;
}


/* Responsiveness for mobile devices */
@media (max-width: 768px) {
    .davinci-title-container {
        margin-bottom: 5px;
    }
    body {
        margin: 5% 2%;
    }
    main {
        padding: 0;
    }

    .davinci-title {
        font-size: 2rem; /* Smaller font for mobile */
    }

    .davinci-logo {
        width: 80px; /* Adjust logo size for mobile */
    }

    h2 {
        font-size: 1.5rem;
    }

    footer {
        font-size: 0.8rem;
    }
}