/* Donations Section Styling */
#donation {
    margin-top: 40px;
    text-align: center;
    padding: 0 15px; /* Add padding for mobile to prevent content from touching edges */
}

/* Donation Tabs */
.donation-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap; /* Allow the buttons to wrap on smaller screens */
}

.donation-tabs button {
    background-color: #333;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 10px; /* Add margin at the bottom for better spacing on mobile */
    transition: 0.3s;
    flex: 1 1 auto; /* Allow buttons to grow and shrink depending on screen size */
    max-width: 150px; /* Set a max width for tabs */
}

.donation-tabs button:last-child {
    margin-right: 0; /* Remove margin on the last button to avoid extra spacing */
}

.donation-tabs button:hover {
    background-color: #444;
}

.donation-tabs button.active {
    background-color: #ff4500;
}

/* Tab content */
.tabcontent {
    padding: 20px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    margin: 0 10px; /* Add margin for mobile */
}

/* QR Code Styling */
.qr-code {
    max-width: 200px; /* Set a max width for QR code */
    width: 100%; /* Make sure the width adjusts relative to max-width */
    height: auto; /* Maintain aspect ratio */
    margin-top: 15px;
}

/* Responsive design for smaller devices */
@media (max-width: 600px) {
    #donation .qr-code {
        max-width: 150px; /* Smaller size for mobile devices */
    }

    .donation-tabs {
        flex-direction: column; /* Stack buttons vertically on mobile */
    }

    .donation-tabs button {
        max-width: 100%; /* Ensure buttons take full width on small screens */
        margin-right: 0; /* Remove right margin on mobile */
    }

    .tabcontent {
        padding: 5% 0; /* Reduce padding for smaller screens */
        font-size: 12px;

    }
}