/* Upcoming Sets Section Styling */
.sets {
  margin-bottom: 40px; /* Space below Upcoming Sets */
  padding: 0 15px; /* Add padding to prevent content from touching edges on mobile */
}

.sets h2 {
  color: #ffa500;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem; /* Responsive font size */
}

.sets ul {
  list-style-type: none; /* Remove default bullet points */
  padding: 0;
}

.sets ul li {
  margin: 5px 0;
  position: relative;
  padding-left: 25px; /* Space for the custom bullet */
  display: flex;
  align-items: center; /* Vertically center the bullet and text */
  font-size: 1rem; /* Default font size */
}

/* Add a custom bullet before each set */
.sets ul li:before {
  content: '•'; /* You can replace this with any icon or symbol */
  color: #ffa500; /* Custom color for the bullet */
  position: absolute;
  left: 0;
  font-size: 1.5em;
  line-height: 1; /* Set line height to ensure it's vertically centered */
  top: 50%; /* Position the bullet relative to the text */
  transform: translateY(-50%); /* Adjust positioning to vertically center it */
}

/* Responsive design for smaller devices */
@media (max-width: 768px) {
  .sets h2 {
    font-size: 1.8rem; /* Slightly smaller font size for mobile */
  }

  .sets ul li {
    font-size: 0.9rem; /* Reduce the font size for smaller screens */
    padding-left: 20px; /* Adjust padding for mobile */
  }

  .sets ul li:before {
    font-size: 1.2em; /* Reduce bullet size for mobile */
  }
}

/* Very small devices */
@media (max-width: 480px) {
  .sets h2 {
    font-size: 1.5rem; /* Even smaller font size for very small screens */
  }

  .sets ul li {
    font-size: 0.8rem; /* Further reduce font size for very small screens */
    padding-left: 15px; /* Reduce padding for very small screens */
  }

  .sets ul li:before {
    font-size: 1em; /* Reduce bullet size for very small screens */
  }
}
