/* Container for title and logo, centers both horizontally and vertically */
.davinci-title-container {
    display: flex;
    justify-content: center;  /* Centers the content horizontally */
    align-items: center;      /* Aligns logo and title vertically */
    gap: 15px;                /* Space between the logo and title */
    margin-bottom: 20px;      /* Space below the entire container */
    flex-direction: row;      /* Ensures they are in a row (logo and title next to each other) */
}

/* Styles for the logo */
.davinci-logo {
    width: 100px;              /* Adjusts the width of the logo */
    height: auto;             /* Maintains the aspect ratio */
}

/* Styles for the title */
.davinci-title {
    font-size: 3rem;          /* Adjusts the title size */
    font-weight: bold;        /* Makes the title bold */
    color: #ffa500;           /* Title color to match the logo color */
}
