/* Contact Us Section Styling */
.info-section {
    margin-bottom: 40px; /* Space below Contact Us */
}

.ourlinks {
    margin-bottom: 10px;
}

.icon {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
    filter: invert(100%) brightness(100%) sepia(0%) hue-rotate(0deg);
    transition: filter 0.3s ease;
}

.ourlinks a {
    text-decoration: none;
    color: white;
}
